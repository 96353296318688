body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;
  color: #333;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  display: inline-block;
}

.section {
  display: block;
}

.grid {
  -ms-grid-rows: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
}

.section-2 {
  width: 500px;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.image {
  border-radius: 400px;
}

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.tweet-right-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 75px;
  margin: 10px 10px 10px 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.button {
  display: inline-block;
  border-radius: 20px;
  text-align: center;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.avatar {
  display: block;
  width: 50px;
  height: 50px;
  padding-top: 0px;
  border-radius: 400px;
}

.tweet-left-box {
  padding-top: 10px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.text-field {
  margin-bottom: 0px;
  font-size: 20px;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.form {
  padding: 5px;
}

.form-block {
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.submit-button {
  position: static;
  display: block;
  margin-top: 5px;
  float: right;
  border-radius: 400px;
}

.tweet-username-handle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tweet-author-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block {
  color: #868686;
  font-size: 12px;
  font-weight: 400;
}

.tweet-message-text-replied {
  position: relative;
  margin: auto auto 0px;
  font-size: 10px;
}

.title-left-box {
  padding-top: 15px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.top-box-title {
  margin-right: 5px;
  font-size: 19px;
  font-weight: 800;
}

.tweet-icon-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 40px;
  margin-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.w-input,
.w-select {
  display: block;
  width: 99%;
  height: 38px;
  margin-bottom: 10px;
  font-size: 19px;
  line-height: 25px;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 0px solid #cccccc;
}

textarea {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark-color(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: textarea;
  background-color: -internal-light-dark-color(white, black);
  -webkit-rtl-ordering: logical;
  flex-direction: column;
  resize: none;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin: 0em;
  font: 400 25px Arial;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(169, 169, 169);
  border-image: initial;
  padding: 2px;
}

textarea.w-input,
textarea.w-select {
  height: auto;
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898ec;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 400px;
  text-align: center;
}

.submit-button {
  position: static;
  display: block;
  margin-top: 5px;
  float: right;
  border-radius: 400px;
  font-weight: 700;
}

.tweet-username {
  margin-right: 5px;
  font-weight: 700;
}

.tweet-icon-item {
  color: rgb(136, 153, 166);
  width: 40px;
  cursor: pointer;
}

.text-block-5 {
  font-size: 12px;
}

.tweet-handle {
  color: #868686;
  font-size: 14px;
  font-weight: 400;
}

.tweet-sent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
}

.text-block-6 {
  margin-left: 5px;
}

.tweets-container {
  display: block;
  max-width: 578px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.tweet-wrapper {
  background-color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 1.5px solid #e6ecf0;
  border-bottom: 1.5px solid #e6ecf0;
  border-left: 1.5px solid #e6ecf0;
  -o-object-fit: fill;
  object-fit: fill;
}

.tweet-container-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tweet-message-replied {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.div-block-11 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tweet-message-text {
  position: relative;
  margin: 2px auto 6px;
}

.profile-box-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: white;
  min-height: 430px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 1px solid #e6ecf0;
  border-bottom: 1px solid #e6ecf0;
  border-left: 1px solid #e6ecf0;
  -o-object-fit: fill;
  object-fit: fill;
}

.top-box-wrapper {
  background-color: #e4e6e7;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 45px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px solid #e6ecf0;
  -o-object-fit: fill;
  object-fit: fill;
}

.avatar-large {
  position: relative;
  left: 10px;
  top: 67px;
  display: block;
  width: 134px;
  height: 134px;
  padding-top: 0px;
  border: 4px solid #000;
  border-radius: 400px;
}

.profile-lower-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 75px;
  margin: 76px 15px auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 137px;
  height: 52px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 3px solid #7f7f7f;
}

.div-block-14 {
  margin-top: 10px;
}

.div-block-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background: white;
  background-image: url(/img/profile-background.jpg);
  background-position: 0px 0px;
  background-size: cover;
}

.splash {
  background-image: url(/img/splash-background.png);
  background-size: center;
  background-position: center;
}

.profile-username {
  margin-right: 5px;
  font-size: 19px;
  font-weight: 800;
}

.text-block-6-copy {
  margin-top: 10px;
}

.tweet-message-replied-copy {
  display: block;
  font-weight: 700;
}

.y-button {
  display: inline-block;
  padding: 1px 8px;
  margin: 8px 5px 8px 0px;
  background-color: #3898ec;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 400px;
}

.search-result-wrapper {
  background-color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 45px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 1px solid #e6ecf0;
  border-bottom: 1px solid #e6ecf0;
  border-left: 1px solid #e6ecf0;
  -o-object-fit: fill;
  object-fit: fill;
}

.search-result-right-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 50px;
  margin: 10px 10px 10px 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -ms-flex-pack: distribute;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.search-box-wrapper {
  background-color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 45px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 1px solid #e6ecf0;
  border-bottom: 1px solid #e6ecf0;
  border-left: 1px solid #e6ecf0;
  -o-object-fit: fill;
  object-fit: fill;
}

.search-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 45px;
  margin: 10px 10px 10px 8px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  max-width: 348px;
  padding: 2px 25px 2px 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 0px solid #000;
  border-radius: 20px;
  background-color: #f5f5f5;
}

.div-block-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-block-2 {
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.user-search-text {
  width: 100%;
  height: 25px;
  margin-bottom: 0px;
  background-color: hsla(0, 0%, 95.3%, 0);
}

.w-input2 {
  display: block;
  width: 100%;
  height: 25px;
  padding: 0px 0px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

*:focus {
  outline: none;
}

@media screen and (max-width: 767px) .navbar-section-desktop {
  display: none;
}

.navbar-section-desktop {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: auto;
  display: -ms-grid;
  display: grid;
  width: 100%;
  min-height: 100px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;

  pointer-events: none;
}

.navbar-center {
  width: 578px;
  pointer-events: none;
}

.navbar-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 100px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  pointer-events: auto;
}

.navbar-right {
  min-width: 100px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.navbar-left-content {
  width: 160px;
  margin-right: 10px;
  padding-left: 20px;
  text-align: left;
}

.navbar-left-margin {
  width: 55px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.navbar-menu-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 23px;
}

.navbar-menu-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 5px;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.navbar-menu-text {
  margin-left: 22px;
  font-size: 19px;
  font-weight: 800;
}

.text-block-9 {
  font-size: 19px;
  font-weight: 700;
}

.text-block-10 {
  font-size: 19px;
  font-weight: 700;
}

.navbar-main-logo {
  position: relative;
  right: 6px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 47px;
  height: 47px;
  margin-top: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 40px;
  background-color: #bfbfbf;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
}

.navbar-main-logo-wrapper {
  padding-bottom: 20px;
}

.mobile-section {
  display: none;
  height: 53px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #3898ec;
}

.mobile-navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 578px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.navbar-menu-icon-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 7px;
  font-size: 19px;
  font-weight: 700;
  text-align: center;
}

.navbar-logo {
  position: relative;
  right: 8px;
  width: 47px;
  height: 47px;
  padding-top: 2px;
  padding-left: 0px;
}

@media screen and (max-width: 991px) {
  .mobile-section {
    display: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #3898ec;
  }
  .mobile-navbar {
    width: 578px;
  }
}

@media screen and (max-width: 767px) {
  .navbar-spacer {
    margin-top: 53px;
  }
  .navbar-section-desktop {
    display: none;
  }
  .mobile-section {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #000;
  }
  .mobile-navbar-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    height: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    font-size: 19px;
  }
}

@media screen and (max-width: 479px) {
  .avatar {
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
  }
  .tweet-left-box {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .text-field {
    overflow: scroll;
    -o-object-fit: fill;
    object-fit: fill;
  }
  .form {
    margin: 5px;
  }
  .form-block {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .textarea {
    overflow: scroll;
    max-height: 45px;
  }
}

.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.sign-in-form-label {
  position: relative;
  top: 2px;
  width: 100%;
  height: 22px;
  margin-bottom: 0px;
  padding-top: 4px;
  padding-left: 12px;
  border-radius: 3px;
  background-color: #f4f9fc;
  font-size: 15px;
  font-weight: 400;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.text-field-4 {
  height: 32px;
  border: 0px solid #000;
  background-color: #f2f2f2;
  font-size: 19px;
}

.sign-in-form-field {
  width: 100%;
  height: 32px;
  border-style: solid;
  border-width: 0px 0px 1.5px;
  border-color: #000 #000 #68658b;
  border-radius: 3px;
  background-color: #e8eeff;
  font-size: 19px;
  padding: 8px 12px 8px 12px;
  margin-bottom: 10px;
}

.submit-button-copy {
  position: static;
  display: block;
  width: 100%;
  margin-top: 5px;
  float: right;
  border-style: solid;
  border-width: 1px;
  border-color: #3898ec;
  border-radius: 400px;
  background-color: #fff;
  color: #3898ec;
  font-weight: 700;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.sign-in-form {
  display: block;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.email-form-section {
  padding-right: 7px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.password-form-section {
  padding-left: 7px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.or {
  margin-top: 10px;
  text-align: center;
}

.social-signin-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #3898ec;
  border-radius: 20px;
  background-color: #ececec;
  cursor: pointer;
}

.social-signin-label {
  padding-left: 5px;
  color: #3898ec;
  font-weight: 700;
  text-align: center;
}

.sign-in-button {
  position: static;
  display: block;
  width: 100%;
  margin-top: 5px;
  float: right;
  border-style: solid;
  border-width: 1px;
  border-color: #3898ec;
  border-radius: 400px;
  background-color: #fff;
  color: #3898ec;
  font-weight: 700;
  padding: 9px 15px;
  cursor: pointer;
}

.forgot-password {
  position: relative;
  bottom: 24px;
  width: 100%;
  height: 0px;
  font-size: 13px;
}

.submit-button {
  position: static;
  display: block;
  margin-top: 5px;
  float: right;
  border-radius: 400px;
}
